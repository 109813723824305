import React from "react";
import { ref, uploadString, getDownloadURL } from "firebase/storage";
import { fireStorage } from "../firebase/firebase";

export default function useFirebase() {
  const pushToFirebase = async (data, setLoading, callback) => {
    const { file, name, ext } = data;
    const storageRef = ref(fireStorage, `/images/${name}${ext}`);
    try {
      setLoading(true);
      const uploadedFile = await uploadString(storageRef, file, "data_url");
      // After successfully uploading, get the download URL
      const downloadURL = await getDownloadURL(storageRef);

      // You can now use 'downloadURL' as the URL to download the uploaded file
      console.log("Download URL:", downloadURL);

      // Optionally, you can invoke a callback function with the download URL
      if (callback) {
        callback(downloadURL);
      }
    } catch (err) {
      console.group("Error");
      console.log(err);
      console.groupEnd();
      setLoading(false);
    }
  };

  return {
    pushToFirebase,
  };
}
