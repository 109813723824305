// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getStorage} from 'firebase/storage';
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnk-6GnSkVW245RT6IxAmtL9J2z07nlPg",
  authDomain: "kisan-dashboard.firebaseapp.com",
  projectId: "kisan-dashboard",
  storageBucket: "kisan-dashboard.appspot.com",
  messagingSenderId: "87582847207",
  appId: "1:87582847207:web:07cea8a1e7aae8f0db3242"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fireStorage = getStorage(app);
export const db = getFirestore(app);