import React from "react";
import { AboutText, FooterText, Heading, PageContent } from "./index.style";
// import { Wrapper } from "../../layout/userLayout/index.style";

const ThankYou = () => {
  return (
    <PageContent>
      <Heading>धन्यवाद</Heading>
      <AboutText>
        "परिणाम 10 अक्टूबर, 2023 को Facebook और Instagram के माध्यम से श्रीराम
        के सोशल मीडिया पेज पर घोषित किए जाएंगे।"
      </AboutText>
      <p>प्रतियोगिता के परिणामों की जानकारी के लिए फॉलो करें</p>
      <a href="https://www.facebook.com/Shriram.SFS/">
        <img src="./icons/facebook-icon.png" alt="" />
      </a>
      {/* <FooterText>
        <span>Designed & Developed by </span>{" "}
        <img src="/icons/covana.png" alt="" />{" "}
      </FooterText> */}
    </PageContent>
  );
};

export default ThankYou;
