import styled, { css } from "styled-components";
import imageFrame from "./images/image-frame-transparent.png";
import { Link } from "react-router-dom";

export const StyledCaptureImageContainer = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  z-index: 2;
  @media (max-width: 400px) {
    & {
      margin-top: 35px;
    }
  }
`;

export const StyledImageContainer = styled.div`
  width: 90%;
  height: auto;
  position: relative;
  padding-bottom: 90%; /* 4:3 aspect ratio (adjust as needed) */

  img[id="image-frame"] {
    width: 100%;
    height: auto;
    display: block;
    position: absolute;
    object-fit: cover;
    z-index: 2; /* Increase the z-index to bring it to the front */
  }
  img[id="captured-image"] {
    position: absolute;
    border-radius: 8%;
    width: 100%;
    height: 100%;
    border-color: transparent;
    object-fit: cover;
    z-index: 1;
  }
  @media (max-width: 400px) {
    & {
      width: 75%;
      padding-bottom: 75%;
    }
  }
`;

export const StyledCaptureContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relaaive; /* Add this line to make the container relative */

  button {
    width: 105px;
    min-width: fit-content;
    height: 50px;
    font-size: large;
    border-radius: 0;
    font-weight: 700;
    border-radius: 12px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.314) 2px -1px 44px 0px;
    position: absolute;
    bottom: -70px;
  }

  img[id="camera-icon"] {
    width: 73px;
    margin-top: 25px;
    color: #fff;
  }

  div {
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 32.6px;
  }
`;

export const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img[id="gallery-upload"] {
    width: 52px;
    margin-top: 20px;
  }

  div {
    font-size: 18px;
    font-weight: 700;
    line-height: 23.47px;
    text-align: center;
  }

  .react-webcam {
    width: 100%; /* Set the desired width */
    height: auto; /* Auto height to maintain aspect ratio */
    max-width: 400px; /* Set a maximum width if needed */
  }
`;

export const StyledSaveOrRetakeContainer = styled.div`
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  box-shadow: darkred;
`;
export const CameraBtn = styled("span")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  strong {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 3px solid #005eb8;
    box-shadow: 0px 3px 15px -3px #000000;
    border-radius: 50%;
    padding: 0.5em;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .camera {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 375px) {
    & {
      margin-top: 4%;
      div {
        font-size: 12px;
      }
      strong {
        width: 50px;
        height: 50px;
      }
      .camera {
        width: 65px;
        height: 65px;
      }
      .uploadText {
        font-size: 10px;
      }
    }
  }
`;
