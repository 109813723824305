import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(#ffffff, #ffe4b5);
    border: 2px solid #005eb8;
    border-radius: 10px;
    .ant-modal-close {
      width: 25px;
      height: 25px;
      top: 12px;
      right: 15px;
      border-radius: 50%;
      overflow: hidden;
      background: #005eb8;
      .ant-modal-close-x {
        width: 25px;
        height: 25px;
        line-height: 25px;
        background: #005eb8;
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-modal-body {
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff);
      padding: 1em 0.5em;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }

  .ant-modal-header {
    display: none;
  }

  p {
    text-align: center;
    color: #9c9c9c;
  }
`;
export const PhotoArea = styled("div")`
  max-width: 312px;
  margin: auto;
  text-align: center;
  margin-top: 3em;
  border-radius: 10px;
  img {
    width: 80px;
  }
`;
export const UserName = styled("h3")`
  text-align: center;
  margin-top: 0.5em;
  font-weight: 900;
  font-size: 2em;
  color: #005eb8;
`;
export const Items = styled("p")`
  margin-bottom: 1em;
  font-weight: 600;
`;
export const PlayButton = styled(Link)`
  background: linear-gradient(0deg, #005eb8, #005eb8, #005eb850);
  font-weight: 900;
  width: 130px;
  border: 1px solid #005eb8;
  margin: 1em auto;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  padding: 0.2em 0;
  border-radius: 50px;
  font-weight: 700;
  color: #ffffff;
  img {
    width: 30px;
    margin-right: 0.5em;
  }
`;
