import styled from "styled-components";

export const Wrapper = styled("div")`
  // background: #ffffff;
  padding: 0 1em;
  background: linear-gradient(#ffffff, #ffffff, #ffe4b5);
`;

export const StyledBgImages = styled.div`
  display: flex;
  position: absolute; /* The child container is absolutely positioned */
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex; /* Use flexbox for the child elements */
  align-items: flex-end; /* Center vertically */
  justify-content: space-between; /* Center horizontally */
  z-index: 1;
`;

export const StyledButton = styled.button`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  background: ${(props) => props.bgColor};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 22px;
  color: #fff;
  border-style: none;
  box-shadow: darkgreen;
  img {
    width: 20px; /* Adjust the width of the icon as needed */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Add some spacing between the icon and text */
    align-self: center;
  }

  p {
    font-size: ${(props) => `${props.size}px`};
    font-weight: 700;
    margin: 0;
  }
`;
