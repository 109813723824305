import React, { useState } from "react";
import QuestionContainer from "./components/QuastionContainer";
import AnswersContainer from "./components/AnswersContainer";
import { StyledHeading, StyledQuizContainer } from "./styles";
import bgImage from "./images/quiz-backgroup-img.png";
import Typography from "antd/lib/typography/Typography";
import Title from "antd/lib/typography/Title";
import QuizModal from "../../components/modals/quizModal";

const Quiz = () => {
  return (
    <>
      <StyledQuizContainer>
        <StyledHeading level={2}>
          सबसे सटीक टुकड़ा पहेली में लगा कर पोस्टर को पूरा करें
        </StyledHeading>
        <QuestionContainer key={0} index={0} />
        <AnswersContainer />
      </StyledQuizContainer>
    </>
  );
};

export default Quiz;
