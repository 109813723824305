import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button, Modal } from "antd";

import { CameraBtn, StyledCaptureContainer } from "../styles";
import cameraIcon from "../images/camera-icon.png";

const CameraCapture = ({
  isCaptured,
  setIsCaptured,
  setCapturedImage,
  setFileInfo,
}) => {
  const webcamRef = useRef(null);
  const [startCapture, setStartCapture] = useState(false);
  const [open, setOpen] = useState(false);
  const cameraRef = useRef();

  const handleStartCapture = () => {
    cameraRef.current.click();
    setStartCapture(true);
  };

  const handleImageSelect = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file
    if (selectedFile) {
      // Create a FileReader to read the selected file as a data URL
      const reader = new FileReader();

      reader.onload = (e) => {
        // Callback function when the file reading is complete
        const imageSrc = e.target.result; // Get the data URL of the selected image

        // Extract the file extension from the file name
        const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

        // Set the captured image and file info with the correct file extension
        setCapturedImage(imageSrc);
        setFileInfo({ name: Date.now(), ext: `.${fileExtension}` });
        setIsCaptured(true);
        setOpen(false);
      };
      // Read the selected file as a data URL
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <StyledCaptureContainer>
      <input
        style={{ display: "none" }}
        ref={cameraRef}
        id="cameraFileInput"
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageSelect}
      />
      <>
        <CameraBtn onClick={handleStartCapture}>
          <strong className="camera">
            <img  src="/icons/cam.png" />
          </strong>
          <div>क्लिक करें</div>
        </CameraBtn>
      </>
    </StyledCaptureContainer>
  );
};

export default CameraCapture;
