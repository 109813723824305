import React, { useState } from "react";
import {
  CardHeader,
  GiftArea,
  StyledHeading,
  StyledQuizSuccessContainer,
} from "./Styles";

// Images
import giftImage from "./images/gift-image.png";
// Components
import SuccessBtn from "./components/SuccessBtn";
import PhotoDetailsModal from "../../components/modals/photoDetails";
import { useNavigate } from "react-router-dom";

const QuizSuccess = () => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(true);
  const navigate = useNavigate();
  const showPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };
  const handleCancel = () => {
    setIsPhotoModalOpen(false);
  };

  const handleSuccess = () => {
    navigate("/capture-image");
  };

  return (
    <>
      {/* <PhotoDetailsModal visible={isPhotoModalOpen} setVisible={handleCancel} /> */}
      <StyledQuizSuccessContainer>
        <CardHeader>
          <StyledHeading>पहेली सुलझाने के लिए धन्यवाद</StyledHeading>
          <SuccessBtn
            address={handleSuccess}
            text={"प्रतियोगिता शुरू करें"}
          ></SuccessBtn>
        </CardHeader>
        {/* Gift IMAGE */}
        <GiftArea>
          <img src={giftImage} />
        </GiftArea>
      </StyledQuizSuccessContainer>
    </>
  );
};

export default QuizSuccess;
