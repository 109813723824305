import styled from "styled-components";

export const StyledShareImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 87vh;
  position: relative;
  gap: 20px;
  z-index: 3;
  img[id="share-image"] {
    border-radius: 9%;
    margin-top: 52px;
    width: 90%;
    padding: 5px;
  }
  img[id="share-able"] {
    width: 100%;
    border-radius: 8%;
  }
  .share-able-container {
    padding: 5px;
    width: 90%;
  }
  @media (max-width: 400px) {
    & {
      height: 83vh;
      img[id="share-image"] {
        border-radius: 8%;
        margin-top: 32px;
        width: 75%;
      }

      .share-able-container {
        width: 75%;
      }
    }
  }
`;

export const StyledContent = styled.p`
  width: 80%;
  text-align: center;
  font-size: 15px;
  line-height: 19px;
  font-weight: 600;
  position: relative;
  z-index: 2;
`;

export const StyledWrapper = styled.div`
  img[id="gift-image"] {
    width: 150px;
  }
`;
