import { Modal } from "antd";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    border-radius: 10px;
    box-shadow: none;
    .ant-modal-body {
      padding: 1em 0.6em;
      font-size: 14px;
      background: #00000010;
    }
    .ant-modal-close {
      width: 25px;
      height: 25px;
      top: -72px;
      right: 10px;
      border-radius: 50%;
      overflow: hidden;
      background: #ffff;
      .ant-modal-close-x {
        width: 25px;
        height: 25px;
        line-height: 25px;
        background: #ffffff;
        svg {
          color: #000000;
        }
      }
    }
  }
  .ant-modal-header {
    display: none;
  }
`;

export const VideoContainer = styled("div")`
  padding: 0em 0 0;
  .video-container {
    max-width: 382px;
    margin: auto;
    border-radius: 10px;
    iframe {
      width: 100%;
    }
  }

  @media (max-width: 400px) {
    & {
      .video-container {
        // iframe {
        //   width: 340px;
        //   height: 186px;
        // }
      }
    }
  }
`;
