import React, { useRef } from "react";
import { CameraBtn, StyledUploadContainer } from "../styles";
import galleryIcon from "../images/galary-icon.png";
import { getFileExt, getFileName } from "../helpers";
export default function Upload({
  setCapturedImage,
  setIsUploaded,
  setFileInfo,
}) {
  const ref = useRef();
  const handleOpen = () => {
    ref.current.click();
  };

  const handleSelectImage = ({ target: { files } }) => {
    if (files.length > 0) {
      const selectedFile = files[0];
      // Create a FileReader
      const reader = new FileReader();

      // Define a callback function for when the file is loaded
      reader.onload = (event) => {
        // event.target.result contains the Base64 data URL of the image
        const base64Image = event.target.result;

        // You can now use base64Image as needed, e.g., set it in state
        setCapturedImage(base64Image);
        setFileInfo({
          name: getFileName(selectedFile),
          ext: getFileExt(selectedFile),
        });
        setIsUploaded(true);
      };

      // Read the selected file as a Data URL (Base64)
      reader.readAsDataURL(selectedFile);
    }
  };

  return (
    <StyledUploadContainer>
      <CameraBtn>
        <strong>
          <img onClick={handleOpen} src="/icons/upload.png" />
        </strong>
        <div className="uploadText">गैलरी से अपलोड करें</div>
      </CameraBtn>
      <input
        onChange={handleSelectImage}
        style={{ display: "none" }}
        type="file"
        accept="image/png,image/jpeg"
        ref={ref}
      />
    </StyledUploadContainer>
  );
}
