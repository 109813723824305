import styled from "styled-components";

export const HeaderContainer = styled("div")`
  background-color: #ffffff;
  padding: 0.2em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px -1px 44px 0px #00000050;
  position: relative;
  z-index: 2;
  img {
    width: 75px;
  }
  @media (max-width: 385px) {
    & {
      img {
        width: 65px;
      }
    }
  }
`;
