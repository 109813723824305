import styled from "styled-components";

export const FooterContainer = styled("div")`
  background-color: #005eb8;
  text-align: center;
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 0.2em;
  z-index: 11111;
  a {
    color: #ffffff;
    width: 85px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    margin: 0.5em auto;
    font-weight: 700;
    flex-direction: column;
    cursor: pointer;
  }
`;
