import React, { useEffect, useState } from "react";

import { useDrop } from "react-dnd";
import Swal from "sweetalert2";

import { StyledImageContainer } from "../styles";
import mainQuizImage from "../images/quiz-main-image.png";
import emptyBucket from "../images/empty-bucket.png";
import QuizModal from "../../../components/modals/quizModal";
import { useNavigate } from "react-router-dom";
import QuizCorrectModal from "../../../components/modals/quizCorrectModal";

const QuestionContainer = () => {
  const [isAnswerCurrect, setIsAnswerCurrect] = useState(false);
  const [inCurrect, setInCurrect] = useState(false);
  const navigate = useNavigate();

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "ANSWER",
    drop: (item) => checkIsAnswerCurrect(item?.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
    onDragEnter: () => {
      alert("enter");
    },
  }));

  const checkIsAnswerCurrect = (answerID = null) => {
    if (answerID === 2) {
      setIsAnswerCurrect(true);
      message("success");
    } else {
      message("fail");
    }
  };

  const message = (type) => {
    switch (type) {
      case "success":
        // navigate("/quiz-success");
        handleCorrectOpen();
        break;
      case "fail":
        handleOpen();
        break;
    }
  };
  const handleCorrectOpen = () => {
    setIsAnswerCurrect(true);
  };
  const handleOpen = () => {
    setInCurrect(true);
  };
  const handleCancel = () => {
    setInCurrect(false);
    setIsAnswerCurrect(false);
  };

  return (
    <StyledImageContainer canDrop={isOver}>
      <QuizModal visible={inCurrect} setVisible={handleCancel} />
      <QuizCorrectModal visible={isAnswerCurrect} setVisible={handleCancel} />
      <img id="main-image" src={mainQuizImage} />
      {!isAnswerCurrect && (
        <img ref={drop} id="empty-bucket" src={emptyBucket} />
      )}
    </StyledImageContainer>
  );
};

export default QuestionContainer;
