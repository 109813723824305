import React, { createContext, useState } from "react";

export const ShareImageContext = createContext();

const ShareImageContextProvider = ({ children }) => {
  const [shareImage, setShareImage] = useState({
    file: "",
    fileName: "",
    ext: "",
  });
  return (
    <ShareImageContext.Provider value={{ shareImage, setShareImage }}>
      {children}
    </ShareImageContext.Provider>
  );
};

export default ShareImageContextProvider;
