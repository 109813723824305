import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";
import { StyledDraggableImage } from "../styles";

const DraggableAnswer = ({ img, id }) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "ANSWER",
      item: { id, img },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    []
  );

  return (
    <>
      <StyledDraggableImage
        ref={drag}
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        <img src={img} alt={`Answer ${id}`} />
      </StyledDraggableImage>
    </>
  );
};

export default DraggableAnswer;
