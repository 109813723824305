// MARFAT
/** If we want to share image on facebook as it shares from mobile device without showing any address.
 * So for this just we have to convert the image to binary large object BLOB. and then bind it into file and then pass to navigator
 */
import React, { useContext, useState, useRef } from "react";
import { toBlob } from "html-to-image";
import { ShareImageContext } from "../../context/ShareImageContext";
import {
  StyledContent,
  StyledShareImageContainer,
  StyledWrapper,
} from "./styles";

import shareIcon from "./images/share-icon.png";
import { BiLogoFacebookCircle } from "react-icons/bi";
import { PiInstagramLogoFill } from "react-icons/pi";
import { Helmet } from "react-helmet";
// IMages
import giftImage from "./images/right-side-gift-image.png";
import mobileImage from "./images/left-size-mobile-image.png";
import { StyledBgImages, StyledButton } from "../../common/index.style";
import { shareOnFacebook } from "./helpers";
import { useNavigate } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";

export default function ShareImage() {
  const { shareImage } = useContext(ShareImageContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSharedCompleted = () => {
    setLoading(false);
    navigate("/thank-you");
  };

  function convertBase64ToBlob(base64) {
    const byteCharacters = atob(
      base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "")
    );
    const byteArray = new Uint8Array(byteCharacters.length);
    debugger;
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    return new Blob([byteArray], { type: "image/png" }); // Change the type to 'image/png' if needed
  }
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    console.log(byteArrays);

    return new File(byteArrays, "pot", { type: contentType });
  }

  const imageRef = useRef(null);

  async function base64ToBlob(base64String, contentType) {
    // Split the base64 string into data and metadata
    const parts = base64String.split(";base64,");
    const type = contentType || parts[0].split(":")[1];
    const byteCharacters = atob(parts[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type });
    const data = {
      files: [
        new File([blob], "image.png", {
          type: blob.type,
        }),
      ],
      title: "Image",
      text: "image",
    };

    try {
      await navigator.share(data);
      handleSharedCompleted();
    } catch (err) {
      console.error(err);
    }
  }

  const handleShare = async () => {
    const newFile = await toBlob(imageRef.current);
    debugger;
    const data = {
      files: [
        new File([newFile], "image.png", {
          type: newFile.type,
        }),
      ],
      title: "Image",
      text: "image",
    };

    try {
      await navigator.share(data);
      handleSharedCompleted();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <StyledShareImageContainer>
      <Helmet>
        <meta property="og:image" content={shareImage.fileRef} />
      </Helmet>
      <img id="share-image" src={shareImage?.share?.file} />
      <StyledButton
        width={145}
        height={36}
        bgColor="linear-gradient(#3887FE, #005EB8)"
        style={{ zIndex: 9999 }}
        onClick={() => {
          base64ToBlob(shareImage?.share?.file);
        }}
      >
        <img src={shareIcon} />
        <p>शेयर करें</p>
      </StyledButton>

      <StyledContent>
        फ्रेम के साथ ली गयी सेल्फी को,{" "}
        <BiLogoFacebookCircle style={{ fontSize: 18 }} /> और{" "}
        <PiInstagramLogoFill style={{ fontSize: 18 }} /> पर #Shriram #Vigyaan
        लिख कर पोस्ट करें, और iPhone जीतने का सुनहरा मौका पायें
      </StyledContent>

      <StyledWrapper>
        <StyledBgImages>
          <img src={mobileImage} />
          <img id="gift-image" src={giftImage} />
        </StyledBgImages>
      </StyledWrapper>
    </StyledShareImageContainer>
  );
}
