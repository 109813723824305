import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledQuizSuccessContainer = styled.div`
  height: 90vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 111;
  img[id="sprite"] {
    position: absolute;
    top: 0;
    height: 50%;
    width: 100%;
  }
`;

export const StyledHeading = styled.h2`
  font-family: Noto Sans Devanagari Condensed;
  font-weight: 700;
  font-size: 55px;
  line-height: 72px;
  text-align: center;
  color: #005eb8;
  padding: 0 2px;
  margin-top: 1em;
`;

export const StyledSuccessBtn = styled.button`
  width: 206px;
  height: 51.93px;
  margin-top: 1em;
  background: linear-gradient(#f5b547, #ffa100);
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  line-height: 28.69px;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  border: none;
  position: relative;
  z-index: 1;
`;
export const CardHeader = styled("div")`
  position: relative;
  height: 450px;
  background: url("/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const GiftArea = styled("div")`
  max-width: 321px;
  position: absolute;
  bottom: 30px;
  margin: auto;
  left: 0;
  right: 0;
  img {
    width: 100%;
  }
`;
