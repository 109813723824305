import React from "react";
import { ModalContainer, PhotoArea, PlayButton, UserName } from "./index.style";

const QuizCorrectModal = ({ visible, setVisible }) => {
  return (
    <ModalContainer
      open={visible}
      onCancel={setVisible}
      footer={false}
      centered
    >
      <PhotoArea>
        <img src="/icons/correct.svg" alt="" />
      </PhotoArea>
      <UserName>सही टुकड़ा</UserName>

      <PlayButton to="/quiz-success">आगे बढ़ें</PlayButton>
    </ModalContainer>
  );
};

export default QuizCorrectModal;
