import React, { useContext, useState } from "react";
import { CircleSpinnerOverlay } from "react-spinner-overlay";
import { useNavigate } from "react-router-dom";

import { StyledSaveOrRetakeContainer } from "../styles";
import saveIcon from "../images/save-icon.png";
import retakeIcon from "../images/retake-icon.png";
import { downloadImage } from "../helpers";
import { ShareImageContext } from "../../../context/ShareImageContext";
import { StyledButton } from "../../../common/index.style";
import useFirebase from "../../../hooks/useFirebase";

export default function SaveOrRetake({
  setIsCaptured,
  setIsUploaded,
  capturedImage,
  capturedImageRef,
  fileInfo,
  imageRef
}) {
  const { setShareImage } = useContext(ShareImageContext);
  const [compositeImage, setCompositeImage] = useState();
  const navigate = useNavigate();
  const { pushToFirebase } = useFirebase();
  const [loading, setLoading] = useState(false);

  const handleReCapture = (setIsCaptured) => {
    setIsCaptured(false);
    setIsUploaded(false);
  };

  const handleSave = () => {
    downloadImage(capturedImageRef, capturedImage, (compositImage) => {
      let tempImage = { file: compositImage, ...fileInfo };
      pushToFirebase({ ...tempImage }, setLoading, (uploadedImgRef) => {
        navigate("/share-image");
        setShareImage({ share: tempImage, fileRef: uploadedImgRef, imageRef });
      });
    });
  };

  return (
    <StyledSaveOrRetakeContainer>
      {loading && <CircleSpinnerOverlay color="#f4b043" />}

      {/* Save */}
      <StyledButton
        width={185}
        height={46}
        size={25}
        bgColor="linear-gradient(#00d609, #005c04)"
        onClick={handleSave}
      >
        <img src={saveIcon} />
        <p>सेव करें</p>
      </StyledButton>

      {/* Retake */}
      <StyledButton
        width={145}
        height={46}
        size={20}
        bgColor="linear-gradient(#FF0000, #B80000)"
        onClick={() => handleReCapture(setIsCaptured)}
      >
        <img src={retakeIcon} />
        <p>वापस खीचें</p>
      </StyledButton>
    </StyledSaveOrRetakeContainer>
  );
}
