export const handleSaveImage = (fileUrl, fileName) => {
  // Create an anchor element with the file URL and trigger a click event to initiate the download
  const anchor = document.createElement("a");
  anchor.href = fileUrl;
  anchor.download = fileName || ""; // Optional: Specify the downloaded file's name
  anchor.click();
};

// export const downloadImage = (imageRef, capturedImage, callback) => {
//   // Create a canvas element
//   const canvas = document.createElement("canvas");
//   const context = canvas.getContext("2d");

//   // Set the canvas size to match the image size
//   const image = imageRef.current;
//   canvas.width = image.width;
//   canvas.height = image.height;

//   // Draw the background image
//   const backgroundImage = new Image();
//   backgroundImage.src = capturedImage;
//   backgroundImage.style.borderRadius = "22px";
//   context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

//   // Draw the src image on top of the background
//   context.drawImage(image, 0, 0, canvas.width, canvas.height);

//   // Create a data URL for the composite image
//   const compositeImage = canvas.toDataURL("image/png");

//   // Create a temporary anchor element for downloading
//   const downloadLink = document.createElement("a");
//   downloadLink.href = compositeImage;

//   const timestemp = Date.now();
//   const fileName = `${timestemp}.png`;

//   callback(compositeImage, fileName);

//   downloadLink.download = "composite_image.png";

//   // Trigger a click event to start the download
//   downloadLink.click();
// };

export const downloadImage = (imageRef, capturedImage, callback) => {
  // Create a canvas element
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  // Set the canvas size to match the image size
  const image = imageRef.current;
  canvas.width = 600;
  canvas.height = 600;

  // Create a clip path with rounded corners
  const cornerRadius = 22; // Adjust this value as needed
  context.beginPath();
  context.moveTo(cornerRadius, 0);
  context.lineTo(canvas.width - cornerRadius, 0);
  context.arcTo(canvas.width, 0, canvas.width, cornerRadius, cornerRadius);
  context.lineTo(canvas.width, canvas.height - cornerRadius);
  context.arcTo(
    canvas.width,
    canvas.height,
    canvas.width - cornerRadius,
    canvas.height,
    cornerRadius
  );
  context.lineTo(cornerRadius, canvas.height);
  context.arcTo(
    0,
    canvas.height,
    0,
    canvas.height - cornerRadius,
    cornerRadius
  );
  context.lineTo(0, cornerRadius);
  context.arcTo(0, 0, cornerRadius, 0, cornerRadius);
  context.closePath();
  context.clip();

  // Draw the background image
  const backgroundImage = new Image();
  backgroundImage.src = capturedImage;

  // Wait for the background image to load before drawing
  backgroundImage.onload = () => {
    context.drawImage(backgroundImage, 0, 0, canvas.width, canvas.height);

    // Draw the src image on top of the background
    const srcImage = imageRef.current;
    context.drawImage(srcImage, 0, 0, canvas.width, canvas.height);

    // Create a data URL for the composite image
    const compositeImage = canvas.toDataURL("image/png", 1.0);

    callback(compositeImage);
    // Create a temporary anchor element for downloading
    const downloadLink = document.createElement("a");
    downloadLink.href = compositeImage;
    downloadLink.download = "composite_image.png";

    // Trigger a click event to start the download
    downloadLink.click();
  };
};

export const getFileName = (file) => {
  return file?.name.split(".")[0] || Date.now();
};

export const getFileExt = (file) => {
  return `.${file?.type.split("/")[1]}` || ".jpeg";
};
