import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../screens/home";
import UserLayout from "../layout/userLayout";
import Quiz from "../Pages/Quiz/Quiz";
import QuizSuccess from "../Pages/Quiz-Success/QuizSuccess";
import CaptureImage from "../Pages/Capture-image/CaptureImage";
import PhotoDetails from "../Pages/photo-details";
import ThankYou from "../Pages/thankYou";
import ShareImage from "../Pages/Share-image/ShareImage";

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<UserLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/quiz-success" element={<QuizSuccess />} />
          <Route path="/capture-image" element={<CaptureImage />} />
          <Route path="/photo-details" element={<PhotoDetails />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/share-image" element={<ShareImage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
