import React from "react";
import { HeaderContainer } from "./index.style";

const Header = () => {
  return (
    <HeaderContainer>
      <img src="./images/log.svg" alt="" />
    </HeaderContainer>
  );
};

export default Header;
