import React from "react";
import { FooterContainer } from "./index.style";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";

const Footer = () => {
  const footerLinks = [
    {
      icon: "home.png",
      address: "/",
      titleName: "होम",
    },
    {
      icon: "span.svg",
      address: "https://shriramfarmsolutions.com/",
      titleName: "श्रीराम वेबसाइट",
    },
    {
      icon: "whatsapp.png",
      address: "https://wa.me/919289034747?text=hi",
      titleName: "व्हाट्सएप्प",
    },
  ];
  return (
    <FooterContainer>
      <Row>
        {footerLinks.map((items, i) => (
          <Col span={8}>
            <Link key={i} to={items.address}>
              <img src={`./icons/${items.icon}`} alt="" />
              <div>{items.titleName}</div>
            </Link>
          </Col>
        ))}
      </Row>
    </FooterContainer>
  );
};

export default Footer;
