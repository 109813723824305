import React from "react";
import { FooterIcons, ModalContainer } from "./index.style";
import Slider from "react-slick";
import { Col, Row } from "antd";

const PhotoModal = ({ visible, setVisible }) => {
  const settings = {
    customPaging: function (i) {
      return (
        <a>
          <img src={`/images/slider-${i + 1}.jpeg`} />
        </a>
      );
    },
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <ModalContainer
      title="गैलरी"
      open={visible}
      onCancel={setVisible}
      footer={false}
    >
      <Slider {...settings}>
        <div>
          <img src={"/images/slider-1.jpeg"} />
        </div>
        <div>
          <img src={"/images/slider-2.jpeg"} />
        </div>
        <div>
          <img src={"/images/slider-3.jpeg"} />
        </div>
        <div>
          <img src={"/images/slider-4.jpeg"} />
        </div>
      </Slider>
      {/* <FooterIcons>
        <Row justify="center" gutter={10}>
          <Col span={8}>
            <div>
              <img src={"/images/slider-1.jpeg"} />
            </div>
          </Col>
          <Col span={8}>
            <div>
              <img src={"/images/slider-2.jpeg"} />
            </div>
          </Col>
          <Col span={8}>
            <div>
              <img src={"/images/slider-3.jpeg"} />
            </div>
          </Col>
        </Row>
      </FooterIcons> */}
    </ModalContainer>
  );
};

export default PhotoModal;
