import { Typography } from "antd";
import styled, { css } from "styled-components";

export const StyledAnswersContainer = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: 31px auto;
  position: relative;
  z-index: 2;
`;

export const StyledDraggableImage = styled.div`
  border: 2px solid #f5b547;
  transition: border-color 0.2s ease-in-out;
  border-radius: 10px;
  margin: auto;
  padding: 12px 8px;
  &:hover {
    border-color: #4caf50;
  }
  background-color: #fff;
`;

export const StyledImageContainer = styled.div`
  width: 313px;
  height: 175px;
  max-width: 313px;
  margin: 21px auto;
  position: relative;

  img[id="main-image"] {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: auto;
  }
  img[id="empty-bucket"] {
    position: absolute;
    z-index: 2;
    top: 17px;
    left: 20px;
    filter: ${(props) => (props.canDrop ? "opacity(0.8)" : "1")};
  }
`;

export const StyledQuizContainer = styled.div`
  width: 100%;
  // height: 90vh;
  position: relative; /* Ensure relative positioning for child elements */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeading = styled.div`
  font-size: 30px;
  width: 90%;
  margin-top: 51px;
  justify-content: center;
  text-align: center;
  display: inline-block;
  color: #005eb8;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 0;
  @media (max-width: 385px) {
    & {
      font-size: 27px;
      margin-top: 31px;
    }
  }
`;
