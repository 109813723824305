import React, { useState } from "react";
import { Wrapper } from "../../common/index.style";
import PhotoDetailsModal from "../../components/modals/photoDetails";

const PhotoDetails = () => {
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(true);
  const showPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };

  const handleCancel = () => {
    setIsPhotoModalOpen(false);
  };
  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };
  return (
    <Wrapper>
      <PhotoDetailsModal visible={isPhotoModalOpen} setVisible={handleCancel} />
    </Wrapper>
  );
};

export default PhotoDetails;
