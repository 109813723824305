import React from "react";
import {
  Items,
  ListContainer,
  ListItems,
  ModalContainer,
  PhotoArea,
  PlayButton,
  UserName,
} from "./index.style";

const PhotoDetailsModal = ({ visible, setVisible }) => {
  const userDetails = [
    {
      about: `
      1) किसी भी “श्रीराम संग विज्ञान” की पेंटिंग, होर्डिंग, विक्रेता की दुकान पर लगे बैनर या गाड़ी के साथ सेल्फी लें
      `,
    },
    {
      about: `
      2) अपने फेसबुक या इंस्टाग्राम पेज पर #Shriram और #Vigyaan के साथ फोटो पोस्ट करें
      `,
    },
    {
      about: `
      3) सबसे ज़्यादा लाइक्स पाने पर iPhone जीतने का सुनेहरा मौका
      `,
    },
    {
      about: `
      4) साथ ही मौका पाएं अन्य इनाम भी जीतने का
      `,
    },
    {
      about: `
      5) श्रीराम विज्ञानोत्सव प्रतियोगिता 10 अक्टूबर, 2023 तक लागू है, विजेता किसानों की घोषणा श्रीराम के फेसबुक पेज पर की जायेगी, कृपया पेज से जुड़े रहें
      `,
    },
  ];

  return (
    <ModalContainer open={visible} onCancel={setVisible} footer={false}>
      <ListContainer>
        <PhotoArea>
          <img src="/images/selfi-bn.png" alt="" />
        </PhotoArea>
        <UserName>प्रतियोगिता के नियम</UserName>
        <ListItems>
          {userDetails.map((items, i) => (
            <Items>{items.about}</Items>
          ))}
        </ListItems>
        <PlayButton onClick={setVisible}>
          {/* <img src="/icons/play.png" alt="" /> */}
          शुरु करें
        </PlayButton>
      </ListContainer>
    </ModalContainer>
  );
};

export default PhotoDetailsModal;
