import styled from "styled-components";

export const PageContent = styled("div")`
  max-width: 312px;
  margin: 1em auto;
  background-color: transparent;
  position: relative;
  z-index: 222;
  p {
    margin-top: 1em;
    font-size: 0.95em;
    text-align: center;
    font-weight: 600;
  }
  a {
    display: block;
    width: 55px;
    margin: 1em auto;
    text-align: center;
  }
`;
export const Heading = styled("h1")`
  text-align: center;
  font-weight: 800;
  color: #005eb8;
  font-size: 6em;
`;
export const AboutText = styled("div")`
  max-width: 260px;
  margin: 1em auto;
  margin-top: 1em;
  padding: 1em 0;
  text-align: center;
  color: #000000;
  font-size: 1.2em;
  position: relative;
  &::before {
    content: "";
    height: 3px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: #f5b547;
  }
  &::after {
    content: "";
    height: 3px;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: #f5b547;
  }
`;
export const FooterText = styled("div")`
  position: fixed;
  bottom: 85px;
  left: 21%;
  span {
    font-weight: 700;
  }
`;
