import { Link } from "react-router-dom";
import styled from "styled-components";

export const TopSection = styled("div")`
  padding: 2em 0 0;
  //   background-color: #000000;
  .slick-slide {
    img {
      width: 100%;
      border-radius: 10px;
    }
  }
  .slick-list {
    border-radius: 12px;
    box-shadow: 0px 4px 15px -4px #000000;
  }
  .ant-carousel {
    .slick-dots {
      display: none !important;
      bottom: -20px;
      li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        button {
          width: 13px;
          height: 13px;
          border-radius: 50%;
          background-color: #ffffff;
          border: 1px solid #005eb8;
          opacity: 1;
        }
      }
      .slick-active {
        button {
          background-color: #005eb8;
        }
      }
    }
  }
  @media (max-width: 385px) {
    & {
      padding: 1em 0px 0px;
      max-width: 96%;
      margin: auto;
    }
  }
`;

export const UploadImageSection = styled("div")`
  background-color: #005eb8;
  margin: 1.3em auto 1em;
  padding-left: 1em;
  border-radius: 10px;
  max-width: 380px;
  height: 200px;
  h2 {
    margin-top: 1em;
    font-size: 2.4em;
    margin-bottom: 0em;
    font-weight: 800;
    color: #ffffff;
  }
  @media (max-width: 395px) {
    & {
      height: 200px;
      h2 {
        font-size: 2.2em;
      }
    }
  }
  @media (max-width: 385px) {
    & {
      max-width: 96%;
      height: 175px;
      margin: 1em auto 0.5em;
      h2 {
        margin-top: 0.5em;
        font-size: 2em;
      }
    }
  }
`;
export const BadgeContainer = styled("div")`
  background-color: #ffe500;
  color: #000000;
  font-weight: 900;
  width: 122px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4em;
  padding: 0.2em 0;
  margin-bottom: 0.5em;
  border-radius: 0;
`;
export const CameraButton = styled(Link)`
  color: #000000;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  font-size: 1.1em;
  margin: 1em auto;
  border-radius: 5px;

  @media (max-width: 385px) {
    & {
      width: 51px;
      margin: 0.3em auto;
    }
  }
`;
export const BgImage = styled("div")`
  background-image: url("/images/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: end;
  height: 200px;
  img {
    margin-left: 1em;
    max-width: 100%;
  }
  @media (max-width: 395px) {
    & {
      height: 200px;
    }
  }
  @media (max-width: 385px) {
    & {
      height: 175px;
      img {
        margin-left: 1em;
        max-width: 100%;
      }
    }
  }
`;
export const QuizSection = styled(Link)`
  display: block;
  max-width: 380px;
  margin: 1em auto;
  background-color: #d5e8fb;
  border-radius: 10px;
  .labelName {
    display: flex;
    justify-content: center;
    align-items: center;
    p {
      margin-bottom: 0;
      font-weight: 800;
      font-size: 2.3em;
      color: #005eb8;
    }
  }
  img {
    width: 100%;
  }
  @media (max-width: 385px) {
    & {
      max-width: 96%;
      margin: 1em auto;
      p {
        font-size: 2em;
      }
    }
  }
`;
export const VideoSection = styled("div")`
  max-width: 380px;
  margin: 1em auto;
  padding-bottom: 5em;
  @media (max-width: 385px) {
    & {
      max-width: 96%;
      margin: 1em auto;
      p {
        font-size: 2em;
      }
    }
  }
`;
export const Card = styled("div")`
  display: block;
  border-radius: 10px;
  text-align: center;
  p {
    background: linear-gradient(0deg, #005eb8, #005eb8, #005eb890);
    border: 1px solid #005eb8;
    font-weight: 900;
    width: 135px;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    padding: 0.4em 0;
    border-radius: 50px;
    color: #ffffff;
  }
`;
export const CardHeader = styled("div")`
  display: block;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  span {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%);
    cursor: pointer;
  }
  img {
    border-radius: 10px;
    width: 100%;
  }
`;
