import React, { useEffect, useRef } from "react";
import { ModalContainer, VideoContainer } from "./index.style";
import YouTube from "react-youtube";

const VideoModal = ({ visible, setVisible }) => {
  const embedUrl = `https://youtu.be/1k6YnAW6ozk`;
  const opts = {
    playerVars: {
      autoplay: 0, // 1 for autoplay
    },
  };
  return (
    <ModalContainer
      open={visible}
      onCancel={setVisible}
      footer={false}
      centered
    >
      <VideoContainer>
        <div className="video-container">
          <YouTube videoId="1k6YnAW6ozk" opts={opts} />
        </div>
      </VideoContainer>
    </ModalContainer>
  );
};

export default VideoModal;
