import React, { useState } from "react";
import { Wrapper } from "../../common/index.style";
import { Carousel, Col, Row } from "antd";
import {
  BgImage,
  CameraButton,
  Card,
  CardHeader,
  QuizSection,
  TopSection,
  UploadImageSection,
  VideoSection,
} from "./index.style";
import VideoModal from "../../components/modals/video";
import PhotoModal from "../../components/modals/photo";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);
  const navigate = useNavigate();

  const showPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };

  const showVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const handleCancel = () => {
    setIsPhotoModalOpen(false);
    setIsVideoModalOpen(false);
  };

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <Wrapper>
      <VideoModal visible={isVideoModalOpen} setVisible={handleCancel} />
      <PhotoModal visible={isPhotoModalOpen} setVisible={handleCancel} />
      <TopSection>
        <Carousel afterChange={onChange}>
          <div>
            <img src="./images/banner-1.jpg" alt="" />
          </div>
          <div>
            <img src="./images/banner-1.jpg" alt="" />
          </div>
          <div>
            <img src="./images/banner-1.jpg" alt="" />
          </div>
        </Carousel>
      </TopSection>
      <UploadImageSection>
        <Row gutter="0" onClick={() => navigate("/capture-image")}>
          <Col span={10}>
            <h2>
              प्रतियोगिता <br /> शुरू करें
            </h2>
            <CameraButton>
              <img src="/icons/play.png" alt="" />
            </CameraButton>
          </Col>
          <Col span={14}>
            <BgImage>
              <img src="/images/salfi.png" alt="" />
            </BgImage>
          </Col>
        </Row>
      </UploadImageSection>
      <QuizSection to="/quiz">
        <Row align="middle">
          <Col span={12}>
            <div className="labelName">
              <p>गेम खेलें</p>
            </div>
          </Col>
          <Col span={12}>
            <img src="./images/quiz.svg" alt="" />
          </Col>
        </Row>
      </QuizSection>
      <VideoSection>
        <Row gutter={10}>
          <Col span={12}>
            <Card>
              <CardHeader onClick={showPhotoModal}>
                <img src="/images/van.jpg" alt="" />
              </CardHeader>
              <p onClick={showPhotoModal}>गैलरी</p>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <CardHeader onClick={showVideoModal}>
                <span>
                  <img src="/icons/play.png" alt="" />
                </span>
                <img src="/images/video.jpg" alt="" />
              </CardHeader>
              <p onClick={showVideoModal}>वीडियो</p>
            </Card>
          </Col>
        </Row>
      </VideoSection>
    </Wrapper>
  );
};

export default Home;
