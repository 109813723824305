import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Wrapper, Content, Container } from "./index.style";
import Footer from "../../components/footer";
import Header from "../../components/header";

// Weet images
import weetLeft from "./images/weet-left.png";
import weetRight from "./images/weet-right.png";
import { StyledBgImages } from "../../common/index.style";

const UserLayout = () => {
  /** Routes in which bg image will be displayed */
  const routes = ["/quiz", "/quiz-success", "/thank-you", "/capture-image"];
  const location = useLocation();

  /** Render bg weet images conditionally,
   * Using this same layout rendring images conditionally.
   */
  const renderBgImage = () => {
    if (routes.includes(location.pathname)) {
      return (
        <StyledBgImages>
          <img src={weetLeft} />
          <img src={weetRight} />
        </StyledBgImages>
      );
    }
  };
  return (
    <Wrapper>
      <Header />
      {renderBgImage()}
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default UserLayout;
