import React, { useContext, useEffect } from "react";

import filledBucketBottomToTop from "../images/filled-bucket-bottom-to-top.png";
import filledBucketTopToBottom from "../images/filled-bucket-top-to-bottom.png";
import filledBucketleftToRight from "../images/filled-bucket-left-to-right.png";

import DraggableAnswer from "./DraggableAnswer";
import { StyledAnswersContainer } from "../styles";

const AnswersContainer = () => {

  const answers = [
    { img: filledBucketTopToBottom, id: 2 },
    { img: filledBucketBottomToTop, id: 1 },
    { img: filledBucketleftToRight, id: 3 },
  ];

  return (
    <StyledAnswersContainer>
      {answers.map((answer) => (
        <DraggableAnswer key={answer.id} img={answer.img} id={answer.id} />
      ))}
    </StyledAnswersContainer>
  );
};

export default AnswersContainer;
