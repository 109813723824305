import { Modal } from "antd";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    max-width: 350px;
    margin: auto;
    border-radius: 10px;
    .ant-modal-body {
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff, #ffe4b5);
    }
    .ant-modal-close {
      width: 25px;
      height: 25px;
      top: 12px;
      right: 15px;
      border-radius: 50%;
      overflow: hidden;
      background: #005eb8;
      .ant-modal-close-x {
        width: 25px;
        height: 25px;
        line-height: 25px;
        background: #005eb8;
        svg {
          color: #ffffff;
        }
      }
    }
  }

  .ant-modal-header {
    border-radius: 10px;
    padding: 16px 24px;
    text-align: center;
    border-bottom: none;
    .ant-modal-title {
      font-weight: 800;
      margin-top: 0.5em;
      font-size: 1.8em;
      color: #005eb8 !important;
    }
  }
  .slick-list {
    margin-bottom: 4em;
  }
  .slick-slide {
    img {
      margin: 0 auto;
      width: 0%;
    }
  }
  .slick-active {
    display: block;
    max-width: 385px;
    height: 385px;
    div {
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        max-width: 385px;
        height: 389px;
        img {
          margin: 0 auto;
          width: 100%;
          height: 380px;
        }
      }
    }
  }
  .slick-dots {
    display: flex !important;
    justify-content: space-between;
    top: 100%;
    bottom: 0;
    li {
      margin: 0;
      margin-top: 0.7em;
      width: 100%;
      height: 50px !important;
      img {
        width: 65px;
        height: 50px;
      }
    }
  }
  .slick-next {
    background: url("/icons/right-icon.png");
    background-size: 100% 100%;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    right: 10px;
    z-index: 1;
    &::before {
      display: none;
    }
  }
  .slick-prev {
    background: url("/icons/left-icon.png");
    background-size: 100% 100%;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    left: 10px;
    z-index: 1;
    &::before {
      display: none;
    }
  }
  @media (max-width: 400px) {
    & {
      .ant-modal-content {
        max-width: 385px;
        margin: auto;
      }
    }
  }
  @media (max-width: 385px) {
    & {
      .ant-modal-content {
        .slick-active {
          height: 285px;
          div {
            div {
              height: 289px;
              img {
                height: 280px;
              }
            }
          }
        }
      }
    }
  }
`;
export const FooterIcons = styled("div")`
  margin-top: 1em;
  img {
    width: 100%;
  }
`;
