import React from "react";
import { ModalContainer, PhotoArea, PlayButton, UserName } from "./index.style";

const QuizModal = ({ visible, setVisible }) => {
  return (
    <ModalContainer
      open={visible}
      onCancel={setVisible}
      footer={false}
      centered
    >
      <PhotoArea>
        <img src="/icons/close.png" alt="" />
      </PhotoArea>
      <UserName>गलत टुकड़ा</UserName>
      <p>सही टुकड़ा चुनें</p>
      <PlayButton onClick={setVisible}>दोबारा कोशिश करें</PlayButton>
    </ModalContainer>
  );
};

export default QuizModal;
