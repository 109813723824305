import React, { useRef, useState } from "react";
import { StyledCaptureImageContainer, StyledImageContainer } from "./styles";

// Imges
import imageFrame from "./images/image-frame.png";

import Capture from "./componentes/Capture";
import Upload from "./componentes/Upload";
import SaveOrRetake from "./componentes/SaveOrRetake";
import PhotoDetailsModal from "../../components/modals/photoDetails";

const CaptureImage = () => {
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCaptured, setIsCaptured] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [fileInfo, setFileInfo] = useState({ name: "", ext: "" });
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(true);
  const showPhotoModal = () => {
    setIsPhotoModalOpen(true);
  };
  const handleCancel = () => {
    setIsPhotoModalOpen(false);
  };
  const capturedImageRef = useRef();
  const imageRef = useRef(null);
  return (
    <StyledCaptureImageContainer>
      <PhotoDetailsModal visible={isPhotoModalOpen} setVisible={handleCancel} />
      {/* Image Frame */}
      <StyledImageContainer ref={imageRef}>
        {/* Forground img */}
        <img ref={capturedImageRef} id="image-frame" src={imageFrame} />
        {/* Background img */}
        <img id="captured-image" src={capturedImage} />
      </StyledImageContainer>

      {/* Capture via Camera */}
      {!isCaptured && !isUploaded && (
        <>
          <Capture
            setCapturedImage={setCapturedImage}
            capturedImageRef={capturedImageRef}
            capturedImage={capturedImage}
            setIsCaptured={setIsCaptured}
            setFileInfo={setFileInfo}
          />
          <Upload
            setCapturedImage={setCapturedImage}
            setIsUploaded={setIsUploaded}
            setFileInfo={setFileInfo}
          />
        </>
      )}

      {/* Save or Retake */}
      {(isCaptured || isUploaded) && (
        <SaveOrRetake
          setIsCaptured={setIsCaptured}
          setIsUploaded={setIsUploaded}
          capturedImage={capturedImage}
          capturedImageRef={capturedImageRef}
          fileInfo={fileInfo}
          imageRef={imageRef}
        />
      )}

      {/* Upload Via Gallery */}
    </StyledCaptureImageContainer>
  );
};

export default CaptureImage;
