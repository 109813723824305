import { useLayoutEffect, useState } from "react";
import "./App.css";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AllRoutes from "./routes";

import ShareImageContextProvider from "./context/ShareImageContext";
import { DndProvider, usePreview } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch"; // or any other pipeline

function App() {
  const MyPreview = () => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { itemType, item, style } = preview;
    console.log(preview);
    return (
      <img
        src={item?.img}
        style={{
          position: "absolute",
          zIndex: 3,
          backgroundColor: "transparent",
          ...style,
        }}
      />
    );
  };

  return (
    <div className="App">
      <DndProvider options={HTML5toTouch}>
        <MyPreview />
        <ShareImageContextProvider>
          <AllRoutes />
        </ShareImageContextProvider>
      </DndProvider>
    </div>
  );
}

export default App;
