import styled from "styled-components";
// Weet images
import weetLeft from "./images/weet-left.png";
import weetRight from "./images/weet-right.png";

export const Wrapper = styled("div")`
  // max-width: 360px;
  margin: 0 auto;
  background: linear-gradient(#ffffff, #ffffff, #ffe4b5);
  position: relative; /* The parent container should be relatively positioned */
`;
export const Content = styled("div")`
  height: 85vh;
  @media (max-width: 400px) {
    height: 85vh;
  }
`;
export const Container = styled("div")`
  height: auto;
`;
