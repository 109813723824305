import { Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const ModalContainer = styled(Modal)`
  .ant-modal-content {
    background: linear-gradient(#ffffff, #ffe4b5);
    border-radius: 10px;
    .ant-modal-close {
      width: 25px;
      height: 25px;
      top: 12px;
      right: 15px;
      border-radius: 50%;
      overflow: hidden;
      background: #005eb8;
      .ant-modal-close-x {
        width: 25px;
        height: 25px;
        line-height: 25px;
        background: #005eb8;
        svg {
          color: #ffffff;
        }
      }
    }
    .ant-modal-body {
      border-radius: 10px;
      background: linear-gradient(#ffffff, #ffffff, #ffffff, #ffe4b5);
      padding: 1em 1.5em;
      font-size: 14px;
      line-height: 1.5715;
      word-wrap: break-word;
    }
  }

  .ant-modal-header {
    display: none;
  }
  @media (max-width: 400px) {
    & {
      .ant-modal-content {
        max-width: 300px;
        margin: auto;
      }
    }
  }
  @media (max-width: 385px) {
    & {
      .ant-modal-content {
        max-width: 300px;
        margin-top: -31px;
      }
    }
  }
`;
export const ListContainer = styled("div")``;
export const PhotoArea = styled("div")`
  max-width: 312px;
  margin: auto;
  height: 156px;
  margin-top: 3em;
  background: #005eb8;
  border-radius: 10px;
  img {
    width: 100%;
    margin-top: -26px;
  }
  @media (max-width: 400px) {
    & {
      height: 141px;
    }
  }
`;
export const UserName = styled("h1")`
  text-align: center;
  font-weight: 900;
  margin-top: 0.5em;
  @media (max-width: 400px) {
    & {
      font-size: 1.1em;
    }
  }
`;
export const ListItems = styled("div")``;
export const Items = styled("p")`
  margin-bottom: 1em;
  font-weight: 600;
  @media (max-width: 400px) {
    & {
      margin-bottom: 0.5em;
      font-size: 0.8em;
    }
  }
`;
export const PlayButton = styled(Link)`
  background: linear-gradient(0deg, #005eb8, #005eb8, #005eb880);
  font-weight: 900;
  width: 150px;
  border: 1px solid #005eb860;
  margin: 1em auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
  padding: 0.2em 0;
  border-radius: 50px;
  font-weight: 700;
  color: #ffffff;
  img {
    width: 30px;
    margin-right: 0.5em;
  }
`;
